.App
  .App_mock
    background-image: url('../../public/images/bg.png')
    margin: 10px 0 20px 0
    .App_mock-body
      text-align: center
    .App_mock-mint
      max-width: 10rem
      margin: auto
    .App_group-btn-mint
      margin: auto

  .introduce
    background: linear-gradient(90deg, #868AED 0%, #4F5299 92.3553466796875%, #4F5299 96.3897705078125%)
    .introduce_left
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center